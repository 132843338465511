import React, { useEffect, useState } from "react";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageLocale } from "@components/Shared/model/LanguageContent";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Button } from "@ryerson/frontend.button";
import { Link, ButtonLink } from "@ryerson/frontend.navigation";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { renderRichText } from "@components/Shared/model/Contentful";
import { css } from "@emotion/react";
import { Input, EmailInput, PhoneInput, Dropdown } from "@ryerson/frontend.form-controls";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import Map from "@components/Locations/Map/Map";
import { Icon } from "@ryerson/frontend.assets";
import { TopicOptions } from "@components/ContactUs/ContactForm/FormContent";
import { pickPhoneNumberFromString } from "@components/Helper/common";
import {
	sendGAEvent,
	GoogleAnalyticsEventName,
	GoogleAnalyticsEventActionNames,
} from "@ryerson/frontend.common";

export type LocationSolutionCapability = {
	title: string;
	link: string;
	icon: any;
	description: any;
};

export type LocationLatLong = {
	lat: number;
	lon: number;
};

export type LocationHours = {
	sundayOpen?: string;
	sundayClose?: string;
	mondayOpen?: string;
	mondayClose?: string;
	tuesdayOpen?: string;
	tuesdayClose?: string;
	wednesdayOpen?: string;
	wednesdayClose?: string;
	thursdayOpen?: string;
	thursdayClose?: string;
	fridayOpen?: string;
	fridayClose?: string;
	saturdayOpen?: string;
	saturdayClose?: string;
};

export type LocationTeamMember = {
	image: any;
	name: string;
	phoneNumber: number;
	title: string;
	cdtMember: boolean;
};

export type ContentfulDetail = {
	blurb: any;
	locationName: string;
	locationCode: string;
	country: string;
	details: any;
	enableContactLocation: boolean;
	localSolutionsCapabilities: LocationSolutionCapability[];
	location: LocationLatLong;
	locationHours: LocationHours;
	locationPicture: any;
	managementTeamMember: LocationTeamMember[];
	node_locale: "en-US" | "fr-CA" | "es-MX";
	phoneNumber: number;
	state: string;
	streetAddress: string;
	city: string;
	zipCode: any;
};

export type LocationStaticContent = {
	localNumberLabel: string;
	call: string;
	afterHoursService: string;
	getDirections: string;
	contactThisLocation: string;
	sunday: string;
	monday: string;
	tuesday: string;
	wednesday: string;
	thursday: string;
	friday: string;
	saturday: string;
	managementTeam: string;
	localSolutionsCapabilities: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	company: string;
	zip: string;
	topicLabel: string;
	blankTopicVerbiage?: string;
	careersTopicVerbiage?: string;
	onlineStoreTopicVerbiage?: string;
	productQuestionTopicVerbiage?: string;
	quoteRequestTopicVerbiage?: string;
	requestSalesContactTopicVerbiage?: string;
	mediaContactTopicVerbiage?: string;
	otherTopicVerbiage?: string;
	message: string;
	sendMessage: string;
	backToOtherLocations: string;
};

export type ContentfulNode = {
	node: ContentfulDetail;
};

export type LocationProps = {
	content: LocationStaticContent;
	contentfulContent: ContentfulNode[];
};

const BreadcrumbContainer = styled.div`
	padding-top: 52px;
	margin-bottom: 20px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding-top: 26px;
				margin-bottom: 12px;
			}
		`;
	}}
`;

const MainContent = styled.div`
	width: 100%;
	display: block;
	margin-top: 55px;
`;

const Left = styled.div`
	width: calc(100% - 400px);
	display: inline-block;
	vertical-align: top;
	height: auto;
`;

const Right = styled.div`
	width: 390px;
	display: inline-block;
	vertical-alight: top;
	margin-left: 10px;
	height: auto;
`;

const DetailsLeft = styled.div`
	width: calc(95% - 200px);
	display: inline-block;
	vertical-align: top;
	padding-right: 5%;
	box-sizing: border-box;
	height: auto;
`;

const DetailsRight = styled.div`
	width: 200px;
	display: inline-block;
	vertical-align: top;
	height: auto;
`;

const Divider = styled.hr`
	display: block;
	width: 100%;
	opacity: 0.1;
`;

const ButtonLinkContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;
const BigSpacer = styled.div`
	display: block;
	width: 100%;
	height: 140px;
`;

const SolutionBox = styled.div`
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 300px;
	padding: 20px;
	box-sizing: border-box;
	margin-top: 40px;
	max-width: calc(45% - 20px);
	margin-right: 40px;
	height: auto;
	border-radius: 2px;
	&:nth-child(odd) {
		margin-right: 0px;
	}

	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				margin-top: 20px;
				width: 100%;
				max-width: 540px;
				background-color: ${theme.colors.secondary.background};
				margin-left: auto;
				&:nth-child(odd) {
					margin-right: auto;
				}
			}
		`;
	}}
`;

const MobileHours = styled.div`
	display: block;
	width: 100%;
	max-width: 210px;
`;

const SolutionIcon = styled.div`
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	position: absolute;
	top: 20px;
	left: 20px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

const FormContainer = styled.div`
	display: block;
	width: 100%;
	max-width: 460px;
	height: auto;
`;

const MapContainer = styled.div`
	display: block;
	width: 100%;
	border-radius: 2px;
	height: 251px;
	overflow: hidden;
`;

const PictureContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	border-radius: 2px;
	img {
		width: 100%;
		height: auto;
		display: block;
	}
`;

const TeamMember = styled.div`
	display: block;
	width: 230px;
	height: auto;
	padding-left: 100px;
	box-sizing: border-box;
	position: relative;
	margin-top: 20px;
	min-height: 80px;
	margin-bottom: 10px;
`;

const TeamMemberPortrait = styled.div`
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 80px;
	height: 80px;
	border-radius: 80px;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

type ContentPanelProps = {
	type: "primary" | "secondary" | "tertiary";
	vPadding: string;
	hPadding: string;
	theme: any;
};

const ContentPanel = styled.div`
	display: block;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	border-radius: 2px;
	${(props: ContentPanelProps) => {
		const { type, vPadding, hPadding, theme } = props;
		return css`
			background-color: ${theme.colors[type].background};
			padding: ${vPadding} ${hPadding} ${vPadding} ${hPadding};
		`;
	}}
`;

const Messager = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-top: 20px;
`;

const MessagerIcon = styled.div`
	display: inline-block;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	vertical-align: middle;
	box-sizing: border-box;
	border-radius: 30px;
	text-align: center;
	line-height: 38px;
`;

const MessagerText = styled.div`
	display: inline-block;
	vertical-align: middle;
	width: calc(100% - 40px);
	box-sizing: border-box;
`;

export const FormatPhoneNumber = (phoneNumber: number | string) => {
	let cleaned = ("" + phoneNumber).replace(/\D/g, "");
	let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return match[1] + "-" + match[2] + "-" + match[3];
	}
	return null;
};

type HoursLineType = {
	open?: string;
	close?: string;
	label: string;
};

const HoursLine: React.FC<HoursLineType> = ({ open, close, label }) => {
	const { theme } = useTheme();
	return (
		<>
			{open && open.length > 0 && close && close.length > 0 ? (
				<Flex
					justifyContent="space-between"
					css={css`
						height: 28px;
						line-height: 28px;
					`}
					direction="row"
				>
					<FlexItem>
						<Typography variant="span" type="primary" size="md">
							{label}
						</Typography>
					</FlexItem>
					<FlexItem>
						<Typography
							variant="span"
							type="primary"
							size="md"
							color={theme.colors.primary.header}
						>
							{open} — {close}
						</Typography>
					</FlexItem>
				</Flex>
			) : (
				<></>
			)}
		</>
	);
};

const Location: React.FC<LocationProps> = ({ content, contentfulContent }) => {
	const { theme } = useTheme();
	const {
		axiosInstance,
		localization: { language },
	} = useApplication();

	const inputStyling = css`
		box-sizing: border-box;
		margin-bottom: 20px;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			margin-bottom: 15px;
		}
	`;
	const [languageContent, setLanguageContent] = React.useState<ContentfulDetail>(
		contentfulContent[0].node
	);
	React.useEffect(() => {
		contentfulContent.forEach((data: ContentfulNode) => {
			if (LanguageLocale[data.node.node_locale] === language) setLanguageContent(data.node);
		});
	}, [language]);

	const [topicVerbiage, setTopicVerbiage] = useState(content.blankTopicVerbiage);

	const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false);
	const [firstName, setFirstName] = React.useState<string>("");
	const [lastName, setLastName] = React.useState<string>("");
	const [email, setEmail] = React.useState<string>("");
	const [phoneNumber, setPhoneNumber] = React.useState<string>("");
	const [zip, setZip] = React.useState<string>("");
	const [company, setCompany] = React.useState<string>("");
	const [topic, setTopic] = React.useState<string>("");
	const [message, setMessage] = React.useState<string>("");
	const [showAPIMessage, setShowAPIMessage] = React.useState<boolean>(false);
	const [apiMessage, setAPIMessage] = React.useState<string>("");
	const [apiStatus, setAPIStatus] = React.useState<string>("success");

	const handleTopicChange = (value: string) => {
		setTopic(value);
	};

	const displayAddlTopicVerbiage = (value: string) => {
		if (value === "") {
			setTopicVerbiage(content.blankTopicVerbiage);
		} else if (value === "careers") {
			setTopicVerbiage(content.careersTopicVerbiage);
		} else if (value === "onlineStore") {
			setTopicVerbiage(content.onlineStoreTopicVerbiage);
		} else if (value === "productQuestion") {
			setTopicVerbiage(content.productQuestionTopicVerbiage);
		} else if (value === "quoteRequest") {
			setTopicVerbiage(content.quoteRequestTopicVerbiage);
		} else if (value === "requestSalesContact") {
			setTopicVerbiage(content.requestSalesContactTopicVerbiage);
		} else if (value === "mediaContact") {
			setTopicVerbiage(content.mediaContactTopicVerbiage);
		} else {
			setTopicVerbiage(content.otherTopicVerbiage);
		}
	};

	useEffect(() => {
		displayAddlTopicVerbiage(topic);
	}, [language, topic]);

	const onFormSubmit = () => {
		setShowAPIMessage(false);
		setFormSubmitted(true);
		if (
			firstName.length > 0 &&
			lastName.length > 0 &&
			email.length > 0 &&
			topic.length > 0 &&
			zip.length > 0 &&
			message.length > 0
		) {
			let saveObject: object = {
				firstName: firstName,
				lastName: lastName,
				email: email,
				phoneNumber: String(phoneNumber),
				zip: zip,
				company: company,
				topic: topic,
				message: message,
				location: languageContent.locationCode,
			};
			sendGAEvent(GoogleAnalyticsEventName.LOCATION_CONTACT_US, {
				action: GoogleAnalyticsEventActionNames.SUBMIT,
				value: topic,
			});
			axiosInstance
				.post("/api/common/v1/public/contact-us", saveObject)
				.then((response) => {
					if (response.status === 200) {
						setAPIMessage(
							"Thank you, your request has been received! One of our metal professionals will be following up with you shortly to assist."
						);
						setAPIStatus("success");
						setFirstName("");
						setLastName("");
						setEmail("");
						setPhoneNumber("");
						setTopic("");
						setZip("");
						setCompany("");
						setMessage("");
						setFormSubmitted(false);
						setShowAPIMessage(true);
						setTimeout(() => {
							setShowAPIMessage(false);
						}, 8000);
					} else {
						setAPIMessage("Something went wrong. Please try messaging us again later.");
						setFormSubmitted(false);
						setShowAPIMessage(true);
						setTimeout(() => {
							setShowAPIMessage(false);
						}, 8000);
					}
				})
				.catch(() => {});
		} else {
			setAPIStatus("error");
			setAPIMessage("Please enter all required fields.");
			setShowAPIMessage(true);
			setTimeout(() => {
				setShowAPIMessage(false);
			}, 8000);
		}
	};
	const contactUsMobileRef: React.RefObject<any> = React.createRef();

	const handleClick = () => {
		const contactForm = document.querySelector(".contact-form");
		if (contactForm) {
			contactForm.scrollIntoView({ behavior: "smooth" });
			if (contactUsMobileRef.current && contactUsMobileFormOpen === false) {
				setContactUsMobileFormOpen(true);
				contactUsMobileRef.current.click();
			}
		}
	};

	const [contactUsMobileFormOpen, setContactUsMobileFormOpen] = React.useState<boolean>(false);

	return (
		<>
			<ContentSection vPadding="0px" type="tertiary">
				<BreadcrumbContainer theme={theme}>
					<Breadcrumb type="tertiary" size="xs" />
				</BreadcrumbContainer>
				<Typography
					variant="h1"
					css={css`
						margin-top: 0px;
						margin-bottom: 30px;
					`}
					type="tertiary"
				>
					{languageContent.locationName}
				</Typography>
				<Typography
					variant="div"
					type="tertiary"
					size="md"
					css={css`
						width: 100%;
						max-width: 760px;
					`}
				>
					{languageContent.blurb.blurb}
				</Typography>
				<Media lessThan="lg">
					<Spacer />
					<Spacer />
					<Spacer />
				</Media>
				<Media greaterThanOrEqual="lg">
					<MainContent>
						<Left>
							<ContentPanel
								type="primary"
								vPadding="36px"
								hPadding="40px"
								theme={theme}
							>
								<DetailsLeft>
									<Typography
										type="primary"
										size="lg"
										css={css`
											margin-bottom: 25px;
										`}
										color={theme.colors.primary.header}
										weight="bold"
										variant="div"
									>
										{content.localNumberLabel +
											" " +
											FormatPhoneNumber(languageContent.phoneNumber)}
									</Typography>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 26px;
										`}
										type="primary"
										size="md"
									>
										{content.call}
										<Typography
											variant="span"
											type="primary"
											weight="bold"
											color={theme.colors.primary.header}
										>
											{" " +
												pickPhoneNumberFromString(languageContent.state)
													.number +
												" "}
										</Typography>
										{content.afterHoursService}
									</Typography>
									<Divider css={{ color: theme.colors.primary.darkerGray }} />
									<Typography
										variant="div"
										css={css`
											margin-top: 26px;
										`}
										type="primary"
										size="md"
									>
										{languageContent.streetAddress +
											" " +
											languageContent.city +
											" " +
											languageContent.state +
											" " +
											languageContent.zipCode +
											" " +
											languageContent.country}
									</Typography>
									<ButtonLinkContainer
										css={css`
											margin-top: 15px;
											margin-bottom: 36px;
										`}
									>
										<ButtonLink
											orientation="right"
											size="sm"
											label={content.getDirections}
											to={
												"//maps.google.com/?ll=" +
												languageContent.location.lat +
												"," +
												languageContent.location.lon
											}
											linkType="external"
										/>
									</ButtonLinkContainer>
									<Button
										label={content.contactThisLocation}
										size="lg"
										type="primary"
										onClick={handleClick}
									/>
								</DetailsLeft>
								<DetailsRight>
									{languageContent.locationHours ? (
										<>
											<HoursLine
												open={languageContent.locationHours.sundayOpen}
												close={languageContent.locationHours.sundayClose}
												label={content.sunday}
											/>
											<HoursLine
												open={languageContent.locationHours.mondayOpen}
												close={languageContent.locationHours.mondayClose}
												label={content.monday}
											/>
											<HoursLine
												open={languageContent.locationHours.tuesdayOpen}
												close={languageContent.locationHours.tuesdayClose}
												label={content.tuesday}
											/>
											<HoursLine
												open={languageContent.locationHours.wednesdayOpen}
												close={languageContent.locationHours.wednesdayClose}
												label={content.wednesday}
											/>
											<HoursLine
												open={languageContent.locationHours.thursdayOpen}
												close={languageContent.locationHours.thursdayClose}
												label={content.thursday}
											/>
											<HoursLine
												open={languageContent.locationHours.fridayOpen}
												close={languageContent.locationHours.fridayClose}
												label={content.friday}
											/>
											<HoursLine
												open={languageContent.locationHours.saturdayOpen}
												close={languageContent.locationHours.saturdayClose}
												label={content.saturday}
											/>
										</>
									) : (
										<></>
									)}
								</DetailsRight>
								<Spacer />
								<Spacer />
							</ContentPanel>
							<Spacer />
							<ContentPanel
								type="secondary"
								vPadding="50px"
								hPadding="40px"
								theme={theme}
							>
								<Typography
									variant="h2"
									type="secondary"
									css={css`
										margin-top: 0px;
										margin-bottom: 0px;
									`}
								>
									{content.localSolutionsCapabilities}
								</Typography>
								{languageContent.localSolutionsCapabilities.length > 0 ? (
									<>
										{languageContent.localSolutionsCapabilities.map(
											(
												solution: LocationSolutionCapability,
												index: number
											) => {
												return (
													<SolutionBox
														theme={theme}
														key={index}
														css={css`
															background-color: ${theme.colors.primary
																.background};
														`}
													>
														{solution.icon !== null &&
														solution.icon.file.url ? (
															<SolutionIcon>
																<img src={solution.icon.file.url} />
															</SolutionIcon>
														) : (
															<></>
														)}

														<Typography
															variant="div"
															weight="bold"
															css={css`
																display: block;
																width: 100%;
																padding-left: ${solution.icon !==
																	null && solution.icon.file.url
																	? "60px"
																	: "0px"};
																box-sizing: border-box;
																line-height: 40px;
																margin-bottom: 20px;
															`}
															color={theme.colors.primary.header}
															size="sm"
														>
															{solution.link &&
															solution.link.length > 0 ? (
																<Link
																	underline={true}
																	color={
																		theme.colors.primary.header
																	}
																	to={solution.link}
																	gatsby={true}
																>
																	{solution.title}
																</Link>
															) : (
																<>{solution.title}</>
															)}
														</Typography>
														<Divider />
														<Typography
															css={css`
																margin-top: 20px;
															`}
															variant="div"
															size="md"
															type="primary"
														>
															{solution.description.description}
														</Typography>
													</SolutionBox>
												);
											}
										)}
									</>
								) : (
									<></>
								)}
								<Spacer />
								<Spacer className="contact-form" />
							</ContentPanel>
							<Spacer />
							<ContentPanel
								type="primary"
								vPadding="40px"
								hPadding="40px"
								theme={theme}
							>
								<Spacer />
								{languageContent.enableContactLocation === true ? (
									<>
										<Typography
											variant="h2"
											type="primary"
											css={css`
												margin-top: 0px;
												margin-bottom: 50px;
											`}
										>
											{content.contactThisLocation}
										</Typography>
										<FormContainer>
											<Input
												label={content.firstName}
												onChange={(e) => {
													setFirstName(e.target.value);
												}}
												value={firstName}
												required={true}
												submitted={formSubmitted}
												language={language}
											/>
											<Spacer />
											<Spacer />
											<Input
												label={content.lastName}
												value={lastName}
												onChange={(e) => {
													setLastName(e.target.value);
												}}
												required={true}
												submitted={formSubmitted}
												language={language}
											/>
											<Spacer />
											<Spacer />
											<EmailInput
												label={content.email}
												value={email}
												onChange={(e) => {
													setEmail(e.target.value);
												}}
												required={true}
												submitted={formSubmitted}
												language={language}
											/>
											<Spacer />
											<Spacer />
											<PhoneInput
												label={content.phoneNumber}
												value={phoneNumber}
												onChange={(e) => {
													setPhoneNumber(e.target.value);
												}}
												required={false}
												submitted={formSubmitted}
												language={language}
											/>
											<Spacer />
											<Spacer />
											<div
												css={css`
													display: inline-block;
													vertical-align: top;
													width: calc(100% - 140px);
													margin-right: 20px;
												`}
											>
												<Input
													label={content.company}
													value={company}
													onChange={(e) => {
														setCompany(e.target.value);
													}}
													required={false}
													submitted={formSubmitted}
													language={language}
												/>
											</div>
											<div
												css={css`
													display: inline-block;
													vertical-align: top;
													width: 120px;
												`}
											>
												<Input
													label={content.zip}
													value={zip}
													onChange={(e) => {
														setZip(e.target.value);
													}}
													required={true}
													submitted={formSubmitted}
													language={language}
												/>
											</div>

											<Spacer />
											<Spacer />
											<Dropdown
												label={content.topicLabel}
												size={"md"}
												value={topic}
												onChange={handleTopicChange}
												options={TopicOptions[language].options}
												required={true}
												css={inputStyling}
												language={language}
											/>
											<Typography variant="p">
												{topicVerbiage}
												{topic === "careers" && (
													<Link to="/about-us/careers/open-positions/">
														Click here
													</Link>
												)}
												.
											</Typography>
											<Spacer />
											<Spacer />
											<Input
												label={content.message}
												onChange={(e) => {
													setMessage(e.target.value);
												}}
												inputType="textarea"
												required={true}
												value={message}
												submitted={formSubmitted}
												addStyle={{
													height: "140px",
												}}
												language={language}
											/>
											<Spacer />
											<Spacer />
											<Spacer />
											<Spacer />
											<Spacer />
											<Button
												label={content.sendMessage}
												type="primary"
												onClick={onFormSubmit}
												paddingType="relaxed"
												size="lg"
											/>
											{showAPIMessage && (
												<Messager>
													<MessagerIcon
														css={css`
															background-color: ${apiStatus ===
															"success"
																? theme.colors.secondary.green
																: theme.colors.secondary.red};
														`}
													>
														<Icon
															size="sm"
															color={theme.colors.primary.white}
															icon={
																apiStatus === "success"
																	? "check"
																	: "minus"
															}
														/>
													</MessagerIcon>
													<MessagerText>
														<Typography
															variant="div"
															size="md"
															type="primary"
														>
															{apiMessage}
														</Typography>
													</MessagerText>
												</Messager>
											)}
											<Spacer />
											<Spacer />
											<Spacer />
											<Spacer />
											<Spacer />
											<Spacer />
										</FormContainer>
									</>
								) : (
									<></>
								)}
								<Typography
									variant="div"
									type="primary"
									size="md"
									css={css`
										margin: 0px;
									`}
								>
									{renderRichText(languageContent.details.raw, "primary")}
								</Typography>
								<Spacer />
								<Spacer />
							</ContentPanel>
						</Left>
						<Right>
							<MapContainer
								css={css`
									background-color: ${theme.colors.primary.white};
								`}
							>
								<Map
									googleKey={process.env.GATSBY_GOOGLE_MAPS_KEY ?? ""}
									mapData={[
										{
											location: {
												lat: languageContent.location.lat,
												lng: languageContent.location.lon,
											},
											name: languageContent.streetAddress,
											id: languageContent.streetAddress,
										},
									]}
									zoomLevel={11}
									customMarker="/images/locations/MapMarker.png"
									customMarkerScale={[30, 30]}
								></Map>
							</MapContainer>
							<Spacer />
							{languageContent.locationPicture !== null &&
							languageContent.locationPicture.file.url ? (
								<PictureContainer>
									<img src={languageContent.locationPicture.file.url} />
								</PictureContainer>
							) : (
								<></>
							)}
							<Spacer />

							{languageContent.managementTeamMember !== null ? (
								<ContentPanel
									type="primary"
									vPadding="15px"
									hPadding="40px"
									theme={theme}
								>
									<Typography
										variant="h4"
										css={css`
											margin-top: 0px;
											margin-bottom: 20px;
											font-size: 20px;
										`}
									>
										{content.managementTeam}
									</Typography>
									<Divider />
									{languageContent.managementTeamMember.map(
										(member: LocationTeamMember, index: number) => {
											return (
												!member.cdtMember && (
													<TeamMember key={index}>
														<TeamMemberPortrait>
															<img src={member.image.file.url} />
														</TeamMemberPortrait>
														<Typography
															variant="div"
															color={theme.colors.primary.header}
															size="md"
															weight="bold"
														>
															{member.name}
														</Typography>
														<Typography
															variant="div"
															size="md"
															color={theme.colors.primary.gray}
														>
															{member.title}
														</Typography>
														<Spacer />
														<Typography
															variant="div"
															size="md"
															type="primary"
														>
															{FormatPhoneNumber(member.phoneNumber)}
														</Typography>
													</TeamMember>
												)
											);
										}
									)}

									<Spacer />
								</ContentPanel>
							) : (
								<></>
							)}
						</Right>
					</MainContent>
					<BigSpacer />
				</Media>
			</ContentSection>
			<Media lessThan="lg">
				<ContentSection type="primary">
					<MobileHours>
						{languageContent.locationHours ? (
							<>
								<HoursLine
									open={languageContent.locationHours.sundayOpen}
									close={languageContent.locationHours.sundayClose}
									label={content.sunday}
								/>
								<HoursLine
									open={languageContent.locationHours.mondayOpen}
									close={languageContent.locationHours.mondayClose}
									label={content.monday}
								/>
								<HoursLine
									open={languageContent.locationHours.tuesdayOpen}
									close={languageContent.locationHours.tuesdayClose}
									label={content.tuesday}
								/>
								<HoursLine
									open={languageContent.locationHours.wednesdayOpen}
									close={languageContent.locationHours.wednesdayClose}
									label={content.wednesday}
								/>
								<HoursLine
									open={languageContent.locationHours.thursdayOpen}
									close={languageContent.locationHours.thursdayClose}
									label={content.thursday}
								/>
								<HoursLine
									open={languageContent.locationHours.fridayOpen}
									close={languageContent.locationHours.fridayClose}
									label={content.friday}
								/>
								<HoursLine
									open={languageContent.locationHours.saturdayOpen}
									close={languageContent.locationHours.saturdayClose}
									label={content.saturday}
								/>
							</>
						) : (
							<></>
						)}
					</MobileHours>
					<Spacer />
					<Divider css={{ color: theme.colors.primary.darkerGray }} />
					<Spacer />
					<Typography
						type="primary"
						size="lg"
						css={css`
							margin-bottom: 25px;
						`}
						color={theme.colors.primary.header}
						weight="bold"
						variant="div"
					>
						{content.localNumberLabel}
						<Link to={"tel:" + languageContent.phoneNumber}>
							{" " + FormatPhoneNumber(languageContent.phoneNumber)}
						</Link>
					</Typography>
					<Typography
						variant="div"
						css={css`
							margin-bottom: 24px;
							max-width: 150px;
							height: auto;
						`}
						type="primary"
						size="md"
					>
						{content.call}
						<Typography
							variant="span"
							type="primary"
							weight="bold"
							color={theme.colors.primary.header}
						>
							{" " + pickPhoneNumberFromString(languageContent.state).number + " "}
						</Typography>
						{content.afterHoursService}
					</Typography>
					<Divider css={{ color: theme.colors.primary.darkerGray }} />
					<Typography
						variant="div"
						css={css`
							margin-top: 16px;
						`}
						type="primary"
						size="md"
						color={theme.colors.primary.header}
					>
						{languageContent.streetAddress +
							" " +
							languageContent.city +
							" " +
							languageContent.state +
							" " +
							languageContent.zipCode +
							" " +
							languageContent.country}
					</Typography>
					<ButtonLinkContainer
						css={css`
							margin-top: 15px;
							margin-bottom: 36px;
						`}
					>
						<ButtonLink
							orientation="right"
							size="sm"
							label={content.getDirections}
							to={
								"//maps.google.com/?ll=" +
								languageContent.location.lat +
								"," +
								languageContent.location.lon
							}
							linkType="external"
						/>
					</ButtonLinkContainer>
					<Button
						label={content.contactThisLocation}
						size="lg"
						fullwidth={true}
						type="primary"
						onClick={handleClick}
					/>
					<Spacer />
					<Spacer />
					<Spacer />
					<MapContainer
						css={css`
							background-color: ${theme.colors.primary.white};
						`}
					>
						<Map
							googleKey={process.env.GATSBY_GOOGLE_MAPS_KEY ?? ""}
							mapData={[
								{
									location: {
										lat: languageContent.location.lat,
										lng: languageContent.location.lon,
									},
									name: languageContent.streetAddress,
									id: languageContent.streetAddress,
								},
							]}
							zoomLevel={11}
							customMarker="/images/locations/MapMarker.png"
							customMarkerScale={[30, 30]}
						></Map>
					</MapContainer>
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
					<Accordion multiple={true}>
						{languageContent.localSolutionsCapabilities.length > 0 ? (
							<AccordionTab
								circled={true}
								header={content.localSolutionsCapabilities}
							>
								{languageContent.localSolutionsCapabilities.map(
									(solution: LocationSolutionCapability, index: number) => {
										return (
											<SolutionBox
												key={index}
												theme={theme}
												css={css`
													background-color: ${theme.colors.primary
														.background};
												`}
											>
												{solution.icon !== null &&
												solution.icon.file.url ? (
													<SolutionIcon>
														<img src={solution.icon.file.url} />
													</SolutionIcon>
												) : (
													<></>
												)}

												<Typography
													variant="div"
													weight="bold"
													css={css`
														display: block;
														width: 100%;
														padding-left: ${solution.icon !== null &&
														solution.icon.file.url
															? "60px"
															: "0px"};
														box-sizing: border-box;
														line-height: 40px;
														margin-bottom: 20px;
													`}
													color={theme.colors.primary.header}
													size="sm"
												>
													{solution.link && solution.link.length > 0 ? (
														<Link
															underline={true}
															color={theme.colors.primary.header}
															to={solution.link}
															gatsby={true}
														>
															{solution.title}
														</Link>
													) : (
														<>{solution.title}</>
													)}
												</Typography>
												<Divider />
												<Typography
													css={css`
														margin-top: 20px;
													`}
													variant="div"
													size="md"
													type="primary"
												>
													{solution.description.description}
												</Typography>
											</SolutionBox>
										);
									}
								)}
								<Spacer />
								<Spacer />
								<Spacer />
								<Spacer />
								{languageContent.locationPicture !== null &&
								languageContent.locationPicture.file.url ? (
									<PictureContainer>
										<img src={languageContent.locationPicture.file.url} />
									</PictureContainer>
								) : (
									<></>
								)}
								<Spacer />
								<Spacer />
								<Spacer />
								<Spacer />
								<Spacer className="contact-form" />
							</AccordionTab>
						) : (
							<></>
						)}

						{languageContent.enableContactLocation === true ? (
							<AccordionTab
								circled={true}
								header={content.contactThisLocation}
								tabHeaderRef={contactUsMobileRef}
								onClick={() => {
									setContactUsMobileFormOpen(!contactUsMobileFormOpen);
								}}
							>
								<Spacer />
								<Spacer />
								<Spacer />
								<FormContainer>
									<Input
										label={content.firstName}
										onChange={(e) => {
											setFirstName(e.target.value);
										}}
										required={true}
										value={firstName}
										submitted={formSubmitted}
										language={language}
									/>
									<Spacer />
									<Spacer />
									<Input
										label={content.lastName}
										onChange={(e) => {
											setLastName(e.target.value);
										}}
										required={true}
										value={lastName}
										submitted={formSubmitted}
										language={language}
									/>
									<Spacer />
									<Spacer />
									<EmailInput
										label={content.email}
										onChange={(e) => {
											setEmail(e.target.value);
										}}
										required={true}
										value={email}
										submitted={formSubmitted}
										language={language}
									/>
									<Spacer />
									<Spacer />
									<PhoneInput
										label={content.phoneNumber}
										onChange={(e) => {
											setPhoneNumber(e.target.value);
										}}
										required={false}
										value={phoneNumber}
										submitted={formSubmitted}
										language={language}
									/>
									<Spacer />
									<Spacer />
									<Dropdown
										label={content.topicLabel}
										size={"md"}
										value={topic}
										onChange={handleTopicChange}
										options={TopicOptions[language].options}
										required={true}
										css={inputStyling}
										language={language}
									/>
									<Typography variant="p">
										{topicVerbiage}
										{topic === "careers" && (
											<Link to="/about-us/careers/open-positions/">
												Click here
											</Link>
										)}
										.
									</Typography>
									<Spacer />
									<Spacer />
									<Input
										label={content.message}
										onChange={(e) => {
											setMessage(e.target.value);
										}}
										inputType="textarea"
										required={true}
										value={message}
										submitted={formSubmitted}
										addStyle={{
											height: "140px",
										}}
										language={language}
									/>
									<Spacer />
									<Button
										label={content.sendMessage}
										type="primary"
										onClick={onFormSubmit}
										paddingType="relaxed"
										size="lg"
										fullwidth={true}
									/>
									{showAPIMessage && (
										<Messager>
											<MessagerIcon
												css={css`
													background-color: ${apiStatus === "success"
														? theme.colors.secondary.green
														: theme.colors.secondary.red};
												`}
											>
												<Icon
													size="sm"
													color={theme.colors.primary.white}
													icon={
														apiStatus === "success" ? "check" : "minus"
													}
												/>
											</MessagerIcon>
											<MessagerText>
												<Typography variant="div" size="md" type="primary">
													{apiMessage}
												</Typography>
											</MessagerText>
										</Messager>
									)}
									<Spacer />
									<Spacer />
									<Spacer />
									<Spacer />
									<Spacer />
									<Spacer />
								</FormContainer>
							</AccordionTab>
						) : (
							<></>
						)}

						{languageContent.managementTeamMember !== null && (
							<AccordionTab circled={true} header={content.managementTeam}>
								<Spacer />
								<Spacer />
								{languageContent.managementTeamMember.map(
									(member: LocationTeamMember, index: number) => {
										return (
											!member.cdtMember && (
												<TeamMember key={index}>
													<TeamMemberPortrait>
														<img src={member.image.file.url} />
													</TeamMemberPortrait>
													<Typography
														variant="div"
														color={theme.colors.primary.header}
														size="md"
														weight="bold"
													>
														{member.name}
													</Typography>
													<Typography
														variant="div"
														size="md"
														color={theme.colors.primary.gray}
													>
														{member.title}
													</Typography>
													<Spacer />
													<Typography
														variant="div"
														size="md"
														type="primary"
													>
														{FormatPhoneNumber(member.phoneNumber)}
													</Typography>
												</TeamMember>
											)
										);
									}
								)}

								<Spacer />
								<Spacer />
							</AccordionTab>
						)}
					</Accordion>
					<Divider
						css={css`
							margin-top: 0px;
							margin-bottom: 0px;
						`}
					/>
					<Spacer />

					<Typography
						variant="div"
						type="primary"
						size="md"
						css={css`
							margin: 0px;
						`}
					>
						{renderRichText(languageContent.details.raw, "primary")}
					</Typography>
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
				</ContentSection>
			</Media>
		</>
	);
};

export default Location;
